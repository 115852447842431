@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

$c0: #03a9f4;
$c1: #061422;
$c2: #030e19;
$bg0: rgba($color: $c0, $alpha: .08);
$bg1: rgba($color: $c0, $alpha: .18);
$bg2: rgba($color: $c0, $alpha: .28);
$fc: #fff;
$fs: 15px;
$nav_height: 60px;
$sidebar_width: 300px;
$sidebar_item_height: 50px;