@import './config/vars';

* {
    font-family: 'Inter';
    box-sizing: border-box;
    outline: none !important;
}

html,
body {
    padding: 0;
    margin: 0;
}

* {
    scrollbar-width: auto;
    scrollbar-color: $c0 $bg0;
}

*::-webkit-scrollbar {
    width: 7px;
}

*::-webkit-scrollbar-track {
    background: $bg0;
}

*::-webkit-scrollbar-thumb {
    background-color: $c0;
    border-radius: 10px;
    border: 3px solid transparent;
}

.main-footer,
.main-nav {
    a {
        color: inherit;
        text-decoration: none;
    }
}

body {
    background: $c1;
    color: $fc;
    font-size: $fs;
    font-weight: 500;
}

.app {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .app-content {
        flex-grow: 1;
    }
}

.page {
    height: 100vh;
    width: 100%;
    // padding-bottom: 26px;

    .page-content {
        height: 100%;
        width: 100%;
        padding-top: 62px;
        display: flex;
        flex-direction: column;

        .page-header {
            padding: 0 15px;
            min-height: 55px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .page-content-body {
            flex-grow: 1;
        }
    }

    &.with-sidebar {
        display: flex;

        .page-content {
            width: calc(100% - $sidebar_width);
        }

        .sidebar {
            height: 100vh;
            width: $sidebar_width;
            border-right: 1px solid $bg1;
        }
    }
}

.breadcrumb {
    display: flex;
    list-style: none;
    padding: 10px 0;
    margin: 0;

    li {
        margin-right: 5px;

        img {
            margin-right: 5px;
        }

        input {
            background: none;
            border: none;
            border-bottom: 1px solid $c0;
            color: $fc;
            font-size: $fs;
        }
    }
}

.md-editor {
    background-color: transparent !important;
    height: 100%;

    .md-editor-content-editor {
        min-height: 100%;
    }
}

.ShimmerRight {
    opacity: .05;
}

.mr-1 {
    margin-right: 5px;
}

.mr-2 {
    margin-right: 10px;
}

.mr-3 {
    margin-right: 15px;
}

.text-center {
    text-align: center;
}