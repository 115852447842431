@import "../../config/vars";

.image-radio-group {
    display: flex;
    flex-wrap: wrap;

    label {
        width: 23%;
        margin: 15px 1%;
        cursor: pointer;

        .img {
            width: 100%;
            height: auto;
            padding-top: 100%;
            position: relative;
            background: $bg1;
            border: 1px solid transparent;
            transition: .3s all;
            border-radius: 7px;
            opacity: .7;

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 60%;
                height: 60%;
                object-fit: contain;
                object-position: center;
            }
        }

        span {
            display: block;
            color: $fc;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            margin-top: 5px;
            opacity: .7;
            transition: .3s all;
        }

        input:checked {
            &~.img {
                background: $bg2;
                border-color: $c0;
                opacity: 1;
            }

            &~span {
                opacity: 1;
            }
        }
    }
}