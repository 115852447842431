@import "../../../config/vars";

.main-footer {
    text-align: center;
    font-size: 13px;
    padding: 5px;
    background: rgba($color: $c2, $alpha: .5);
    position: fixed;
    bottom: 0;
    z-index: 40;
    left: 0;
    height: 26px;
    width: 100%;
    display: none;
}