@import "../../config/vars";

.DialogOverlay {
    position: fixed;
    inset: 0;
    animation: overlayShow .3s cubic-bezier(0.16, 1, 0.3, 1);
    background: rgba(0 0 0 / 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    place-items: center;
    overflow-y: auto;
    z-index: 50;
}

.DialogContent {
    background-color: $bg0;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 450px;
    max-height: 85vh;
    animation: contentShow 1s cubic-bezier(0.16, 1, 0.3, 1);
    min-width: 300px;
    z-index: 60;
}

.DialogBody{
    padding: 30px;

}

.DialogContent:focus {
    outline: none;
}

.DialogTitle {
    margin: 0;
    font-weight: 600;
    text-align: center;
    padding: 9px;
    margin: 0;
    font-size: 19px;
    position: relative;
    margin-top: 6px;

    &::after {
        content: '';
        height: 2px;
        background: $c0;
        width: 50%;
        max-width: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 5px;
        transition: .3s all;
        z-index: 2;
    }
}

.DialogFooter{
    padding: 15px;
    text-align: right;
}

.DialogDescription {
    margin: 10px 0 20px;
    font-size: 15px;
    line-height: 1.5;
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}