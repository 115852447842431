@import '../../config/vars';

.input {
    border: none;
    background: $bg1;
    border-radius: 7px;
    padding: 15px;
    font-size: $fs;
    color: $fc;
    font-weight: 500;
    width: 100%;
    transition: .3s all;
    margin-bottom: 15px;

    &::placeholder{
        color: rgb(203, 203, 203);
    }

    &:focus{
        background: $bg2;
    }
}