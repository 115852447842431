@import "../../config/vars";

.img-uploaded {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    margin-bottom: 15px;
    border: 1px solid $c0;
    border-radius: 15px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: .1);
        border: none;
        object-fit: cover;
    }
}