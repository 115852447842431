@import "../../config/vars";

.form-box {
    width: 96%;
    max-width: 600px;
    background-color: $bg0;
    margin: 15px auto;
    padding: 15px 20px;
    border-radius: 10px;

    .form-box-title {
        margin: 0;
        font-weight: 600;
        text-align: center;
        padding: 9px;
        margin: 0;
        font-size: 19px;
        position: relative;
        margin-bottom: 25px;

        &::after {
            content: '';
            height: 2px;
            background: $c0;
            width: 50%;
            max-width: 50px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 5px;
            transition: .3s all;
            z-index: 2;
        }
    }

    .form-box-footer {
        display: flex;
        justify-content: space-between;
        border-top: $bg2 1px solid;
        padding-top: 15px;
        margin-top: 20px;
    }
}