@import "../../config/vars";

.app-preview {
    padding: 0 15px;
    display: flex;
    height: 100%;

    .left {
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
    }

    .right {
        width: 300px;
        // border-left: 1px solid $bg0;
        flex-shrink: 0;
    }
}

.preview-table {
    border-collapse: collapse;
    width: 100%;

    tr {
        td:nth-child(1) {
            background: $bg0;
            width: 0;
            min-width: 150px;
            text-align: right;
            white-space: nowrap;
            font-weight: 600;
            vertical-align: top;
        }

        &.tr-title {
            td {
                text-align: left !important;
                background: none !important;
                padding-bottom: 10px;
                padding-top: 20px;
                color: $c0;
                font-weight: 600;
            }
        }
    }

    td {
        border-bottom: 1px solid $bg0;
        padding: 15px;

        span {
            word-break: break-all;
            white-space: normal;
            line-height: 1.5;
        }
    }
}

.project-side-view {
    .img {
        width: 120px;
        height: 120px;
        border-radius: 10px;
        overflow: hidden;
        display: block;
        margin: auto;
        background: $bg0;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .name {
        text-align: center;
        margin: 0;
        margin-top: 5px;
        font-size: 25px;
    }

    .desc {
        text-align: center;
        font-size: 14px;
        opacity: .6;
        line-height: 1.4;
    }
}