@import "../../../config/vars";

.main-nav {
    display: flex;
    justify-content: space-between;
    background: rgba($color: $c2, $alpha: 0.8);
    backdrop-filter: blur(4px);
    min-height: $nav_height;
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: 62px;
    align-items: center;

    .left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        width: calc($sidebar_width);
        border-right: 1px solid $bg1;

        .brand {

            h2 {
                font-size: 22px;
                font-weight: 600;
            }
        }
    }

    .middle {
        flex-grow: 1;
        padding: 0 15px;

        ul.links {
            list-style: none;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;

            a {
                display: inline-block;
                padding: 8px 12px;
                border-radius: 7px;
                margin-right: 15px;
                transition: all .3s;
                position: relative;

                &:hover {
                    background: $bg1;
                }

                &::after {
                    content: '';
                    height: 2px;
                    background: $c0;
                    width: 50%;
                    max-width: 50px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    border-radius: 5px;
                    opacity: 0;
                    transition: .3s all;
                    z-index: 2;
                }

                &.active {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

    }

}