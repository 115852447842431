.qty-checkbox-pr {
    .i {
        padding: 15px;
        padding-left: 22px;
        position: relative;
        cursor: pointer;
        user-select: none;

        .check-img {
            position: absolute;
            left: 0px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 16px;
            filter: grayscale(1);
            opacity: .5;
            transition: .3s all;
        }
    }

    &.checked {
        .i {
            .check-img {
                filter: none;
                opacity: 1;
            }
        }
    }
}