@import "../../config/vars";

.btn {
    background: $bg1;
    color: $c0;
    font-weight: 600;
    font-size: $fs;
    padding: 8px 12px;
    border-radius: 7px;
    border: none;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
        background: $bg2;
    }

    &.link {
        background: none;
        color: rgb(199, 199, 199);

        &:hover {
            color: #fff;
        }
    }
}