@import '../../config/vars';

.tree-par {
    padding-top: calc($nav_height + 15px);
    background: rgba($color: $c2, $alpha: .5);
    height: 100%;
    height: 100%;
    overflow-y: scroll;
}

.tree-root {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    user-select: none;

    * {
        user-select: none
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &>li:not(.label) {
        margin: 4px 0;
        cursor: pointer;
        position: relative;
        transition: .3s all;

        .i {
            display: flex;
            align-items: center;
            height: $sidebar_item_height;
            transition: .3s all;
            padding: 0px 5px;
            position: relative;
            border-radius: 10px;

            img {
                width: 40px;
                height: 40px;
                margin-right: 14px;
                border: none;
                border-radius: 7px;
                object-fit: contain;
                background-color: $bg1;
            }

            &:hover {
                background: $bg1;

                .edit-btn,
                .add-btn {
                    display: inline-block;
                }
            }
        }

        &::after {
            content: '';
            width: 2px;
            background: $c0;
            height: 60%;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 4px;
            margin: auto;
            border-radius: 5px;
            opacity: 0;
            transition: .3s all;
            z-index: 2;
        }

        &.active {
            &::after {
                opacity: 1;
            }

            background: rgba($color: $c0, $alpha: .25);
        }

        ul {
            transition: 0.4s all;
            height: 0px;
            overflow-y: hidden;

            li:not(.label) {
                padding-left: 25px;
                margin-top: 5px;

                .i {
                    height: calc($sidebar_item_height - 8px);
                }

                img {
                    height: 32px;
                    width: 32px;
                    padding: 5px;
                }
            }
        }

        &.with-nodes {

            .arrow {
                content: '▼';
                position: absolute;
                height: 15px;
                width: 15px;
                right: 10px;
                top: 0;
                bottom: 0;
                margin: auto;
                font-size: 10px;
                transition: 0.3s all;
                transform-origin: center;
            }

            &.opened {

                &>ul {
                    height: auto;
                }

                .arrow {
                    transform: rotateZ(180deg);
                }
            }
        }
    }

    .label {
        font-weight: 700;
        font-size: 14px;
        color: rgb(225, 225, 225);
        padding: 5px 0;
        margin-left: 30px;
        position: relative;

        &::after {
            content: '#';
            color: $c0;
            font-size: 18px;
            font-weight: 700;
            position: absolute;
            left: -20px;
            top: 3px;
            bottom: 0;
            margin: auto;
        }
    }

    &.docs {
        &>li:not(.label) {
            .i {
                height: 35px;

                img {
                    width: 20px;
                    height: 20px;
                    background: none;
                    border-radius: 0;
                    margin-right: 8px;
                }
            }

            ul {
                li:not(.label) {
                    padding-left: 30px;

                    .i {
                        padding: 0 12px;
                        height: auto;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 6px 0;
                        color: rgba($color: $fc, $alpha: 0.7);
                        transition: 0.2s all;

                        img {
                            display: none;
                        }

                        &:hover {
                            background: none;
                            text-decoration: underline;
                            color: $fc;
                        }
                    }
                }
            }
        }
    }

    .edit-btn,
    .add-btn {
        background: none;
        border: none;
        color: $fc;
        cursor: pointer;
        font-size: $fs;
        position: absolute;
        position: absolute;
        height: 25px;
        width: 25px;
        text-align: center;
        right: 60px;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: .8;
        display: none;

        &:hover {
            opacity: 1;
        }
    }

    .add-btn {
        right: 33px;
    }

    .edit-btn{
        display: none !important;
    }
}